<template>
  <Component
    :is="component"
    :[prop]="to"
    :class="[color, mode]"
    class="alert-card"
  >
    <AppIcon
      :icon="icon"
      size="md"
      class="flex-none"
    />
    <div
      class="alert-text"
      :class="textClass"
    >
      <slot name="title">
        <h4
          v-if="title"
          class="flex-none text-lg"
        >
          {{ title }}
        </h4>
      </slot>
      <div
        v-if="$slots['default']"
        class="min-h-0"
        :class="slotClass"
      >
        <slot name="default" />
      </div>
    </div>
    <AppButton
      v-if="onClose"
      iconed
      :icon="config.icons.CLOSE"
      class="flex-none"
      @click="onClose"
    />
  </Component>
</template>

<script setup>
import { computed, toRef } from 'vue'
import AppButton from '@/components/App/AppButton'
import AppIcon from '@/components/App/AppIcon'
import useRouterComponent from '@/composables/useRouterComponent'
import config from '@/config'

const props = defineProps({
  mode: {
    type: String,
    default: 'filled',
    validator: v => ['filled', 'outlined'].includes(v),
  },
  type: {
    type: String,
    default: 'info',
    validator: v => ['info', 'error', 'warning', 'success'].includes(v),
  },
  title: String,
  textClass: [String, Object, Array],
  slotClass: [String, Object, Array],
  to: { type: [String, Object], default: '' },
  onClose: Function,
})
defineEmits(['close'])

const type = toRef(props, 'type')
const to = toRef(props, 'to')
const TYPES = {
  success: {
    icon: config.icons.SUCCESS,
    color: 'bg-success border-success',
  },
  warning: {
    icon: config.icons.WARNING,
    color: 'bg-warning border-warning',
  },
  error: {
    icon: config.icons.ERROR,
    color: 'bg-error border-error',
  },
  info: {
    icon: config.icons.INFO,
    color: 'bg-info border-info',
  },
}
const icon = computed(() => TYPES[type.value]?.icon)
const color = computed(() => TYPES[type.value]?.color)
const { component, prop } = useRouterComponent({ to })
</script>

<style scoped lang="sass">
.alert-card
  @apply flex flex-row justify-center  pl-1 pr-1 py-1 rounded-button items-center
.outlined
  @apply bg-opacity-20 border
.alert-text
  @apply self-stretch flex grow flex-col justify-center min-w-0 pr-2
</style>
